<style scoped></style>

<template>
  <div>
    <fm-table-new
      auto-height full
      :columns="columns"
      :data-list="dataList"
      :simple-filter="true"
      :selection="budgetData && budgetData.status === 'end' ? false : 'left'"
      @on-select-change="onSelectChange"
      @counterChange="counterChange"
      :handler-counter="(datas) => datas.length">
      <div slot="assets" slot-scope="{ row }" style="width: 200px">
        <span :style="{cursor: row.readonly ? 'default' : 'pointer'}" @click="chooseAssets(row)">{{row.goodsAssetsTypeCode && row.goodsAssetsTypeName ? (row.goodsAssetsTypeCode + row.goodsAssetsTypeName) : '关联国有资产编码'}}</span>
      </div>
      <div slot="price" slot-scope="{ row }" style="width: 100px;">
        <fm-input-new :readonly="row.readonly" v-model="row.price" @change="(v) => rowValueChange('price', v, row)" style="width: 100%;">
          <div slot="append">元</div>
        </fm-input-new>
      </div>
      <div slot="num" slot-scope="{ row }" style="width: 120px;">
        <fm-input-number :readonly="row.readonly" v-model="row.num" @input="(v) => rowValueChange('num', v, row)" :min="1" style="width: 100%;"></fm-input-number>
      </div>
      <div slot="total" slot-scope="{ row }" style="width: 120px">
        <fm-input-new :readonly="row.readonly" v-model="row.total" @change="(v) => rowValueChange('total', v, row)" style="width: 100%;">
          <div slot="prepend">共</div>
          <div slot="append">元</div>
        </fm-input-new>
      </div>
      <div slot="preOrg" slot-scope="{ row }">
        <fm-select :disabled="row.readonly" @change="v => rowValueChange('applyOrgId', v, row)" v-model="row.applyOrgId" style="flex: 1;" filterable placeholder="请选择初审科室">
          <fm-option v-for="org in preOrgList" :key="org.key" :label="org.label" :value="org.key"></fm-option>
        </fm-select>
      </div>
      <div slot="fgldUser" slot-scope="{ row }">
        <fm-select :disabled="row.readonly" @change="v => rowValueChange('fgldUserId', v, row)" v-model="row.fgldUserId" style="flex: 1;" filterable placeholder="请选择分管领导">
          <fm-option v-for="user in fgldUserList" :key="user.key" :label="user.label" :value="user.key"></fm-option>
        </fm-select>
      </div>
      <div slot="remark" slot-scope="{ row }" style="width: 200px;">
        <fm-input-new :readonly="row.readonly" v-model="row.remark" @change="(v) => rowValueChange('remark', v, row)" style="flex: 1;width: 100%;" placeholder="备注"></fm-input-new>
      </div>
      <div slot="actions" slot-scope="{ row }" style="word-break: keep-all;white-space: pre;">
        <template v-for="item in row.actions">
          <fm-btn size="small" text :key="item.key" v-if="!['edit'].includes(item.key)" @click="$emit('action', {action: item, data: row})">{{item.label}}</fm-btn>
        </template>
      </div>
    </fm-table-new>
    <fm-modal v-model="status.goods" width="800px">
      <div v-if="chooseData" style="height: 80vh;position: relative;">
        <assets-form :id="chooseData ? chooseData.goodsId : null" @save="onAssetsSave" @close="status.goods = false" />
      </div>
    </fm-modal>
  </div>
</template>

<script>
import Mixins from './mixins'

export default {
  mixins: [Mixins]
}
</script>
