import { render, staticRenderFns } from "./table.vue?vue&type=template&id=cd30e9fe&scoped=true&"
import script from "./table.vue?vue&type=script&lang=js&"
export * from "./table.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../yiweb/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd30e9fe",
  null
  
)

export default component.exports