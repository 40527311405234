<style lang="less" scoped>
  .budget-stage {
    background-color: #F8F8F8;
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 0;
    position: relative;
    box-sizing: border-box;
  }
</style>

<style lang="less" scoped>
  .content {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    height: 0;
    flex: 1;
    display: flex;
    .table {
      border-top: 1px solid #EEE;
      border-right: 1px solid #EEE;
      border-left: 1px solid #EEE;
      background-color: #FFF;
      padding-top: 10px;
      box-sizing: border-box;
      flex: 1;
      display: flex;
      flex-direction: column;
      .action {
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .count-note {
          font-size: 14px;
          color: #F4628F;
        }
      }
      .search {
        padding-bottom: 10px;
      }
      .submit {
        display: flex;
        justify-content: center;
        padding: 10px 0;
        & > * + * {
          margin-left: 50px;
        }
      }
    }
    .plane {
      margin-left: 10px;
      background-color: #FFF;
      padding-top: 10px;
      box-sizing: border-box;
      position: relative;
      width: 350px;
      border-left: 1px solid #EEE;
      border-top: 1px solid #EEE;
      border-right: 1px solid #EEE;
    }
  }
</style>

<style lang="less" scoped>
.switch-btn {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #999;
  & > span {
    cursor: pointer;
    padding: 5px 10px;
    transition: all .3s;
    & + span {
      border-left: 1px solid #999;
    }
  }
}
.row-switch {
  display: flex;
  i {
    color: #657180;
    cursor: pointer;
    font-size: 20px;
    margin-right: 10px;
  }
}
.pink-theme {
  .switch-btn {
    & > span {
      &:hover, &.active {
        color: #F4628F;
      }
    }
  }
  .stages {
    .item {
      &.active {
        color: #F4628F;
        .btn {
          &::before {
            border: 1px solid #F4628F;
            box-shadow: 0 0 5px 0 #F4628F inset;
          }
        }
      }
    }
  }
}
.blue-theme, .white-theme, .dark-theme {
  .switch-btn {
    & > span {
      &:hover, &.active {
        color: #2F5BEA;
      }
    }
  }
  .stages {
    .item {
      &.active {
        color: #2F5BEA;
        .btn {
          &::before {
            border: 1px solid #2F5BEA;
            box-shadow: 0 0 5px 0 #2F5BEA inset;
          }
        }
      }
    }
  }
}
</style>

<style lang="less" scoped>
.org-list {
  .item {
    cursor: pointer;
    border: 1px solid #e8eaec;
    border-radius: 3px;
    background: #f7f7f7;
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    margin: 5px 0 0 0;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: all .3s;
    &:hover {
      background-color: #e8eaec;
    }
    & + .item {
      margin-left: 5px;
    }
  }
}
</style>

<template>
  <div class="budget-stage">
    <div style="border-bottom: 1px solid #EEE;margin: 10px 10px 0 10px;display: flex;position: relative;">
      <div style="height: 80px;width: 0;flex: 1; box-sizing: borer-box; background-color: #FFF;display: flex;align-items: center;border: 1px solid #EEE;padding: 0 40px;">
        <Steps>
          <Step v-for="item in statusFlowList" :status="item.active ? 'process' : undefined" :key="item.key" :title="item.label"></Step>
        </Steps>
      </div>
      <div @click="back" style="height: 80px;width: 80px;background-color: #FFF;margin-left: 10px;cursor: pointer; border: 1px solid #EEE;display: flex;align-items: center;justify-content: center;">
        <!-- 退出 -->
        <Icon type="md-power" size="30" />
      </div>
    </div>
    <div class="content">
      <div class="table" v-loadingx="loading.load">
        <div class="action">
          <div style="display: flex;align-items: center;">
            <div style="font-size: 18px;margin: 0 10px;">{{budgetData ? (budgetData.year + '年') : ''}}预算明细
              <span class="count-note">
                总数: {{status.mode === 'table' ? countNum : showList.length}}
              </span>
            </div>
            <div class="switch-btn" v-if="budgetStatus !== 'end'">
              <span :class="{active: status.show === 'wait'}" @click="status.show = 'wait';chooseDataList=[]">待处理</span>
              <span :class="{active: status.show === 'done'}" @click="status.show = 'done';chooseDataList=[]">已处理</span>
              <span :class="{active: status.show === 'all'}" @click="status.show = 'all';chooseDataList=[]">全部</span>
            </div>
          </div>
          <div class="row-switch">
            <div style="margin-right: 20px;">
              <fm-btn text v-for="item in batchActions" @click="bathDetailsAction(item)" :key="item.key">{{item.label}}</fm-btn>
            </div>
            <i v-if="status.mode !== 'table'" @click="status.mode = 'table'" class="fmico fmico-side-menu"></i>
            <i v-else @click="status.mode = 'block';chooseDataList=[]" class="fmico fmico-menu"></i>
          </div>
        </div>
        <!-- <div class="search">
          <div>
            <fm-input-new placeholder="输入名称搜索" style="width:140px;margin-left:10px;margin-bottom:-1px;" v-model="searchParm.name"></fm-input-new>
            <fm-select placeholder="申请部门搜索" style="margin-left:20px;margin-bottom: 2px;" absolute filterable :clearable="true" v-model="searchParm.budgetApplyOrgId">
              <fm-option v-for="item in applyOrgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
            <fm-select placeholder="状态搜索" style="margin-left:20px;margin-bottom: 2px;" absolute filterable :clearable="true" v-model="searchParm.status">
              <fm-option v-for="item in (config && config.statusList ? config.statusList : [])" :key="item.key" :value="item.key" :label="item.label"></fm-option>
            </fm-select>
          </div>
        </div> -->
        <budget-details-table
          v-if="status.mode === 'table'"
          @action="detailsAction"
          :statusList="config && config.statusList ? config.statusList : []"
          @change="onItemChange"
          @counterChange="counterChange"
          @onSelectChange="onSelectChange"
          :budget-data="budgetData"
          :type="budgetStatus"
          :key="'table' + '' + actionsCheck.add_detail + '-' + status.show"
          style="flex: 1;"
          :data-list="showList"
        />
        <budget-details-block
          v-else
          :key="'block' + '' + actionsCheck.add_detail + '-' + status.show"
          @action="detailsAction"
          @change="onItemChange"
          :budget-data="budgetData"
          style="flex: 1;overflow: auto;"
          :data-list="showList"
        />
        <div class="submit" v-if="$authFunsProxy.viewFile">
          <fm-btn @click="status.file = true">会议材料</fm-btn>
        </div>
      </div>
      <div class="plane" v-if="actionsCheck.add_detail">
        <assets-choose @choose="goodsSubmit" />
      </div>
    </div>
    <fm-modal v-model="status.org" title="请选择申报科室">
      <div class="org-list" v-loadingx="loading.submit">
        <div class="item" @click="goodsSubmit(chooseGoodsData, org)" v-for="org in orgBudgetData" :key="org.id">{{org.applyOrgName}}</div>
      </div>
    </fm-modal>
    <fm-modal title="会议材料" :mask-closable="false" v-model="status.file" @cancel="status.file = false" width="600px">
      <file-manage
        v-if="status.file"
        :file-data-ids="fileDataIds"
        @addFile="addFile"
        :funs="fileFuns"
        @delFile="delFile"
      />
    </fm-modal>
  </div>
</template>

<script>
import store from '@/store'
import FileManage from '@/components/base/FileManage'
import AssetsChoose from './components/assets/search'
import BudgetDetailsBlock from './components/details/block'
import BudgetDetailsTable from './components/details/table'

import { budgetRequest, budgetYearRequest, budgetDetailRequest, statusManageRequest } from '../../api'

export default {
  name: 'BudgetDetails',
  components: { FileManage, AssetsChoose, BudgetDetailsTable, BudgetDetailsBlock },
  data () {
    return {
      status: {
        mode: 'table',
        org: false,
        show: 'wait',
        file: false
      },
      countNum: 0,
      config: null,
      // searchParm: {
      //   budgetApplyOrgId: null,
      //   status: null,
      //   goodsName: null
      // },
      loading: {
        load: false,
        submit: false
      },
      dataList: [],
      oldDataList: [],
      budgetData: null,
      orgBudgetData: [],
      chooseGoodsData: null,
      chooseDataList: []
    }
  },
  computed: {
    batchActions () {
      let data = []
      if (this.chooseDataList.length > 0) {
        data = this.chooseDataList[0].actions || []
        data = data.filter(v => v.key !== 'edit')
        this.chooseDataList.forEach(v => {
          data = data.filter(v1 => v.actions.map(v2 => v2.key).includes(v1.key))
        })
      }
      return data
    },
    budgetId () {
      return this.$route.query.id
    },
    budgetStatus () { // 当前预算所处阶段
      return this.budgetData ? this.budgetData.status : null
    },
    roleType () { // 当前操作员的角色类型
      return this.$route.query.type
    },
    actions () {
      return this.budgetData && this.budgetData.actions ? this.budgetData.actions : []
    },
    actionsCheck () {
      let obj = {}
      this.actions.forEach(v => obj[v.key] = true)
      return obj
    },
    statusFlowList () {
      if (this.budgetStatus) {
        let statusFlow = [
          { label: '填报', key: 'draw', active: ['draw', 'pre', 'second', 'final', 'end'].includes(this.budgetStatus) },
          { label: '初审', key: 'pre', active: ['pre', 'final', 'second', 'end'].includes(this.budgetStatus) },
          { label: '二审', key: 'second', active: ['final', 'second', 'end'].includes(this.budgetStatus) },
          { label: '终审', key: 'final', active: ['final', 'end'].includes(this.budgetStatus) },
          { label: '结束', key: 'end', active: ['end'].includes(this.budgetStatus) }
        ]
        return statusFlow
      } else {
        return []
      }
    },
    showList () {
      let data = []
      if (this.status.show === 'wait') {
        data = this.dataList.filter(v => v.isPending)
      } else if (this.status.show === 'done') {
        data = this.dataList.filter(v => !v.isPending)
      } else {
        data = this.dataList
      }
      // data = data.filter(v => {
      //   let pass = true
      //   if (this.searchParm.budgetApplyOrgId) {
      //     pass = v.budgetApplyOrgId === this.searchParm.budgetApplyOrgId
      //   }
      //   if (pass && this.searchParm.status) {
      //     pass = v.status === this.searchParm.status
      //   }
      //   if (pass && this.searchParm.name) {
      //     pass = v.goodsName && v.goodsName.includes(this.searchParm.name)
      //   }
      //   return pass
      // })
      return data
    },
    fileFuns () {
      return {
        get: this.$authFunsProxy.viewFile,
        upload: this.$authFunsProxy.upload,
        del: this.$authFunsProxy.delFile
      }
    },
    applyOrgList () {
      return this.budgetData && this.budgetData.applyOrgIds ? this.$store.getters.orgList.filter(v => this.budgetData.applyOrgIds.split(',').map(v1 => Number(v1)).includes(v.key)) : []
    },
    fileDataIds () {
      return this.budgetData ? this.budgetData.fileIds : []
    }
  },
  watch: {
    async budgetId () {
      if (this.$route.name === 'budget.details') {
        if (this.budgetId) {
          await this.loadBudgetData()
          this.loadOrgBudget()
          this.loadData()
        } else {
          this.dataList = []
          this.oldDataList = []
          this.budgetData = null
          this.orgBudgetData = []
          this.chooseGoodsData = null
        }
      }
    }
  },
  methods: {
    onSelectChange (data) {
      this.chooseDataList = data
    },
    getUserOrgs () {
      return this.$store.getters.orgList.find(v => v.data.id === this.$store.getters.currentOrgId)
    },
    checkUserOrg () {
      const currentOrgId = this.$store.getters.currentOrgId
      const currentUserId = this.$store.getters.currentUserId
      const orgIds = [
        ...(this.budgetData.applyOrgIds || '').split(','),
        ...(this.budgetData.preOrgIds || '').split(','),
        ...(this.budgetData.finalOrgId ? [this.budgetData.finalOrgId] : [])
      ].map(v => Number(v))
      const userIds = (this.budgetData.fgldUserIds || '').split(',').map(v => Number(v))
      return (currentOrgId && orgIds.includes(currentOrgId)) || (currentUserId && userIds.includes(currentUserId))
    },
    async addFile (data) {
      if (!this.budgetData.fileIds.includes(data.id)) {
        this.budgetData.fileIds.push(data.id)
        budgetYearRequest.files(this.budgetData.id, {
          fileIds: this.budgetData.fileIds.join(',')
        })
      }
    },
    async delFile (data) {
      const index = this.budgetData.fileIds.findIndex(v => data.id === v)
      if (index > -1) {
        this.budgetData.fileIds.splice(index, 1)
        budgetYearRequest.files(this.budgetData.id, {
          fileIds: this.budgetData.fileIds.join(',')
        })
      }
    },
    async onItemChange (field, value, row) {
      const old = this.oldDataList.find(v => v.id === row.id)
      const data = {[field]: value}

      if (old[field] === value) {
        return
      }

      if ((field === 'price' || field === 'num')) {
        data.total = (row.price || 0) * (row.num || 0)
        row = Object.assign(row, data)
      }

      ['total', 'num', 'price'].forEach(key => {
        if (data[key] !== undefined && data[key] === '') {
          data[key] = null
        }
      })

      let confirm = true
      if (field === 'applyOrgId') {
        const org = this.$store.getters.orgList.find(v => v.key === value)
        confirm = org ? await this.$dialog.confirm({
          title: '修改提示',
          content: "是否将该数据初审科室修改为"+ org.label +"？"
        }) : false
      }

      if (field === 'fgldUserId') {
        const user = this.$store.getters.userList.find(v => v.key === value)
        confirm = user ? await this.$dialog.confirm({
          title: '修改提示',
          content: "是否将该数据分管领导修改为"+ user.label +"？"
        }) : false
      }

      if (!confirm) {
        const index = this.dataList.findIndex(v => v.id === row.id)
        index > -1 && this.dataList.splice(index, 1, JSON.parse(JSON.stringify(old)))
      } else {
        Object.keys(data).forEach(key => old[key] = JSON.parse(JSON.stringify(data[key])))
      }

      if (confirm) {
        await budgetDetailRequest.update(row.id, data)
      }
    },
    async bathDetailsAction (action) {
      if (this.chooseDataList.length === 0) {
        this.$notice.error('请选择数据')
        return
      }
      if (action.key === 'draw_switch_wait_pre') {
        if (this.chooseDataList.filter(v => !v.applyOrgId).length > 0) {
          this.$notice.error('部分数据无初审科室，无法提交')
          return
        }
      }
      if (action.key === 'wait_pre_switch_wait_second') {
        if (this.chooseDataList.filter(v => !v.fgldUserId).length > 0) {
          this.$notice.error('部分数据未配置分管领导，无法提交')
          return
        }
      }
      let confirm = await this.$dialog.confirm({ title: '提示', content: '确定' + action.label + '么？' })
      if (!confirm) {
        return
      }
      let plist = this.chooseDataList.map((v) => {
        if (action.key === 'del') {
          return budgetDetailRequest.del(v.id)
        } else {
          let param = {
            statusActionKey: action.key
          }
          if (action.key === 'back_status_switch') {
            param.statusActionRecordId = v.actions.find(v1 => v1.key === action.key).statusActionRecordId
          }
          return budgetDetailRequest.switchStatus(v.id, param)
        }
      })
      this.chooseDataList = []
      await Promise.all(plist)
      this.$notice.success('批量' + action.label + '成功')
      this.loadData()
    },
    async detailsAction ({ action, data }) {
      if (action.key === 'del') {
        this.onItemDel(data)
      } else {
        let confirm = true
        if (action.key === 'draw_switch_wait_pre' && !data.applyOrgId) {
          confirm = false
          this.$notice.error('请选择初审科室')
        }

        if (action.key === 'wait_pre_switch_wait_second' && !data.fgldUserId) {
          confirm = false
          this.$notice.error('请选择分管领导')
        }

        if (confirm) {
          confirm = await this.$dialog.confirm({ title: '提示', content: '确定' + action.label + '么？' })
        }

        if (confirm) {
          let param = { statusActionKey: action.key }, confirm = true

          if (action.key === 'back_status_switch') {
            param.statusActionRecordId = action.statusActionRecordId
          }

          if (confirm) {
            await budgetDetailRequest.switchStatus(data.id, param)
            this.$notice.success(action.label + '成功')
            this.loadData()
          }
        }
      }
    },
    async onItemDel (item) {
      const confirm = await this.$dialog.confirm({ title: '提示', content: '确定删除该条明细么？' })
      if (confirm) {
        await budgetDetailRequest.del(item.id)
        this.$notice.success('删除成功')
        const index = this.dataList.findIndex(v => v.id === item.id)
        if (index > -1) {
          this.dataList.splice(index, 1)
          this.oldDataList.splice(index, 1)
        }
      }
    },
    back () {
      this.$router.push({
        name: 'internalcontrol.budget.' + (this.roleType === 'manage' ? 'manage' : 'handle')
      })
    },
    async loadBudgetData () {
      const res = await budgetYearRequest.get({ id: this.budgetId })
      if (res.length) {
        const budgetData = res[0]
        budgetData.fileIds = (budgetData.fileIds || '').split(',').filter(v => v).map(v => Number(v))
        this.budgetData = budgetData
      } else {
        this.budgetData = null
        this.$notice.warning('年度预算信息不存在')
        this.back()
      }
      if (!this.checkUserOrg()) {
        this.orgUnableNotice()
      }
    },
    async loadData () {
      this.loading.load = true

      let res = null
      if (this.roleType === 'manage') {
        res = await budgetDetailRequest.get({ budgetYearId: this.budgetId })
      } else {
        res = await budgetDetailRequest.getByAuth({ budgetYearId: this.budgetId })
      }
      this.dataList = res.map(item => {
        item.readonly = item.actions.findIndex(v => v.key === 'edit') === -1
        return item
      })
      this.oldDataList = JSON.parse(JSON.stringify(this.dataList))

      this.status.show = this.budgetData.status === 'end' ? 'all' : 'wait'
      this.loading.load = false
    },
    orgUnableNotice () {
      const currentOrg = this.getUserOrgs()
      this.$notice.error({
        title: '当前组织架构无权限操作',
        desc: currentOrg ? '您的当前组织架构为: ' + currentOrg.label : '无当前组织架构'
      })
    },
    async goodsSubmit (data, org = null) {
      if (!this.orgBudgetData.length || !this.checkUserOrg()) {
        this.orgUnableNotice()
      } else if (this.orgBudgetData.length > 1 && !org) {
        this.chooseGoodsData = data
        this.status.org = true
      } else {
        this.loading.submit = true
        await budgetDetailRequest.add({
          goodsId: data.id,
          applyOrgId: null,
          budgetId: org ? org.id : this.orgBudgetData[0].id,
          num: null,
          price: null,
          total: null,
          remark: null
        })

        if (this.status.show === 'done') {
          this.status.show = 'all'
        }

        this.status.org = false
        this.loading.submit = false
        this.$notice.success('明细已新增')
        this.loadData()
      }
    },
    async loadOrgBudget () {
      if (this.actionsCheck.add_detail) {
        this.orgBudgetData = await budgetRequest.getMyOrg({ budgetYearId: this.budgetId })
      }
    },
    async loadConfig () {
      this.config = await statusManageRequest.config({
        statusGroupKey: 'budgetDetail'
      })
    },
    counterChange (data) {
      this.countNum = data
    }
  },
  async beforeRouteEnter (to, from, next) {
    await store.dispatch('loadOrgList')
    await store.dispatch('loadUserList')
    next()
  },
  async mounted () {
    this.loadConfig()
    if (this.budgetId) {
      await this.loadBudgetData()
      this.loadOrgBudget()
      this.loadData()
    } else {
      this.$router.push({ name: 'budget.index' })
    }
  }
}
</script>
